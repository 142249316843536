<template>
  <div>
    <div class="container">
      <ToeflMenu active="3" />
    </div>
    <div style="padding:0 30px">
      <el-radio-group
        v-model="graded"
        @change="showResult"
        style="margin-top: 20px;"
      >
        <el-radio-button label="0">Needs Grading</el-radio-button>
        <el-radio-button label="1">Already Graded</el-radio-button>
      </el-radio-group>

      <div class="text-center" style="margin:15px 0">
        <el-pagination
          background
          @current-change="setPage"
          :page-size="pageSize"
          :current-page="page"
          :total="resultsTotal"
          :pager-count="9"
          layout="prev, pager, next"
        >
        </el-pagination>
      </div>
      <el-form
        :inline="true"
        :model="search"
        class="demo-form-inline"
        style="margin-top:20px"
        size="mini"
      >
        <el-form-item label="Student">
          <el-input
            v-model="search.user_info"
            placeholder="Name/Username/Email"
          ></el-input>
        </el-form-item>
        <el-form-item label="Test Name">
          <el-input
            v-model="search.test_title"
            placeholder="Test Name"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch">Search</el-button>
        </el-form-item>
      </el-form>
      <el-tabs v-model="type" @tab-click="handleClick">
        <el-tab-pane label="All" name="all">
          <span slot="label">
            All
            <span class="badge" v-if="notification.total > 0 && graded === '0'">
              {{ notification.total }}
            </span>
          </span>
          <mock
            v-loading="loading"
            :tableData="tableData"
            :isAdmin="isAdmin"
            :graded="graded"
          ></mock>
        </el-tab-pane>
        <el-tab-pane label="Mock" name="full_mock">
          <span slot="label">
            Mock
            <span
              class="badge"
              v-if="notification.full_mock > 0 && graded === '0'"
              >{{ notification.full_mock }}</span
            >
          </span>
          <mock
            :tableData="tableData"
            :isAdmin="isAdmin"
            :graded="graded"
          ></mock>
        </el-tab-pane>
        <el-tab-pane label="Quick" name="quick_mock">
          <span slot="label">
            Quick
            <span
              class="badge"
              v-if="notification.quick_mock > 0 && graded === '0'"
              >{{ notification.quick_mock }}</span
            >
          </span>
          <mock
            :tableData="tableData"
            :isAdmin="isAdmin"
            :graded="graded"
          ></mock>
        </el-tab-pane>
        <el-tab-pane label="Speaking" name="speaking">
          <span slot="label">
            Speaking
            <span
              class="badge"
              v-if="notification.speaking > 0 && graded === '0'"
              >{{ notification.speaking }}</span
            >
          </span>
          <section-test
            :tableData="tableData"
            :isAdmin="isAdmin"
            :graded="graded"
          ></section-test>
        </el-tab-pane>
        <el-tab-pane label="Writing" name="writing">
          <span slot="label">
            Writing
            <span
              class="badge"
              v-if="notification.writing > 0 && graded === '0'"
              >{{ notification.writing }}</span
            >
          </span>
          <section-test
            :tableData="tableData"
            :isAdmin="isAdmin"
            :graded="graded"
          ></section-test>
        </el-tab-pane>
        <el-tab-pane label="Single Section" name="single_section" v-if="false">
          <span slot="label">
            Single Section
            <span
              class="badge"
              v-if="notification.single_section > 0 && graded === '0'"
              >{{ notification.single_section }}</span
            >
          </span>
          <section-test
            :tableData="tableData"
            :isAdmin="isAdmin"
            :graded="graded"
          ></section-test>
        </el-tab-pane>
      </el-tabs>
      <div class="text-center" style="margin:15px 0">
        <el-pagination
          background
          @current-change="setPage"
          :page-size="pageSize"
          :current-page="page"
          :total="resultsTotal"
          :pager-count="9"
          layout="prev, pager, next"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import role from "@/mixins/role.js";
import TOEFL from "@/apis/toefl.js";
import ToeflMenu from "@/views/toefl/components/ToeflMenu.vue";
import Mock from "@/views/toefl/gradingList/Mock.vue";
import SectionTest from "@/views/toefl/gradingList/SectionTest.vue";

export default {
  metaInfo() {
    return {
      title: "Grading - TOEFL Academy"
    };
  },

  components: {
    ToeflMenu,
    Mock,
    SectionTest
  },

  mixins: [role],

  props: [],
  data() {
    return {
      graded: "0",
      activeName: "All",
      tableData: [],
      loading: true,
      results: [],
      resultsTotal: 0,
      per_page: 0,
      pageSize: 0,
      type: "all",
      notification: {
        full_mock: 0,
        quick_mock: 0,
        single_section: 0,
        speaking: 0,
        total: 0,
        writing: 0
      },
      search: {
        user_info: "",
        test_title: ""
      }
    };
  },
  computed: {
    isAdmin() {
      let isAdmin = this.isRoleAdmin();
      return isAdmin;
    },
    page() {
      let page = 1;
      if (this.$route.query.page === undefined) {
        page = 1;
      } else {
        page = this.$route.query.page;
      }
      return parseInt(page);
    }
  },
  watch: {},

  mounted() {
    if (this.$route.query.is_scored) {
      this.graded = this.$route.query.is_scored;
    }
    if (this.$route.query.user_info) {
      this.search.user_info = this.$route.query.user_info;
    }
    if (this.$route.query.test_title) {
      this.search.test_title = this.$route.query.test_title;
    }
    this.getNotification();
    this.getResults();
    this.type = this.getType();
  },

  methods: {
    onSearch() {
      this.$router.replace({
        path: "/grading",
        query: {
          type: this.$route.query.type,
          page: this.page,
          is_scored: this.graded,
          user_info: this.search.user_info,
          test_title: this.search.test_title
        }
      });
    },
    async getNotification() {
      const res = await TOEFL.getNotification({
        user_info: this.search.user_info,
        test_title: this.search.test_title
      });
      this.notification = res;
    },
    async getResults() {
      let type = this.getType();
      const tableData = await TOEFL.getTransactionsForcorrect({
        is_scored: this.graded,
        category: type,
        page: this.page,
        user_info: this.search.user_info,
        test_title: this.search.test_title
      });
      this.tableData = tableData.data;
      this.loading = false;
      this.resultsTotal = tableData.total;
      this.pageSize = tableData.per_page;
    },
    showResult() {
      this.$router.replace({
        name: "ToeflTestGrading",
        query: {
          type: this.type,
          page: 1,
          is_scored: this.graded,
          user_info: this.search.user_info,
          test_title: this.search.test_title
        }
      });
    },
    handleClick(e) {
      this.$router.replace({
        name: "ToeflTestGrading",
        query: {
          type: e.name,
          page: 1,
          is_scored: this.graded,
          user_info: this.search.user_info,
          test_title: this.search.test_title
        }
      });
    },
    getType() {
      let type = "all";
      if (this.$route.query.type === undefined) {
        type = "all";
      } else {
        type = this.$route.query.type;
      }
      return type;
    },
    handleSelect(index) {
      if (index === "1") {
        this.$router.replace({
          name: "LandingPage"
        });
      }
    },
    setPage(page) {
      this.routerPush({
        name: "ToeflTestGrading",
        query: {
          type: this.getType(),
          page: page,
          is_scored: this.graded,
          user_info: this.search.user_info,
          test_title: this.search.test_title
        }
      });
    }
  }
};
</script>

<style scoped>
.ivyway-title {
  margin: 20px 0;
}
.badge {
  background-color: #d9534f;
  border-radius: 10px;
  color: #ffffff;
  display: inline-block;
  font-size: 12px;
  height: 18px;
  line-height: 16px;
  padding: 0 6px;
  text-align: center;
  white-space: nowrap;
  border: 1px solid #ffffff;
}
</style>
